import { useEffect, useState } from 'react'
import AnchorLink from "react-anchor-link-smooth-scroll";
type Props = {
  bodyHtml: GatsbyTypes.Maybe<string>
}

const yPosOffset = 40

const elementYPos = (id: string): number => {
  const element =  document.getElementById(id)
  return element
    ? Math.trunc(element.getBoundingClientRect().top + window.pageYOffset)
    : 0
}

const TableOfContents = ({bodyHtml}: Props) => {
  let tempTableOfContent = []
  if (bodyHtml) {
    for (const match of bodyHtml.matchAll(/<h(\d)>(.+?)<\/h\d>/g)) {
      tempTableOfContent.push({
        isActive: false,
        headingNumber: match[1],
        headline: match[2],
        yPos: 0,
      })
    }
  }
  const [tableOfContents, setTableOfContents] = useState<{
    isActive: boolean
    headingNumber?: string
    headline?: string
    yPos: number
  }[]>(tempTableOfContent)

  const [scrollTop, setScrollTop] = useState<number>(0);
  const [isFixed, setIsFixed] = useState<boolean>(false);
  const [tableOfContentsYPos, setTableOfContentsYPos] = useState<number>(0);

  const fixedClass: string = isFixed
    ? 'fixed top-10'
    : ''

  useEffect(() => {
    setTableOfContentsYPos(elementYPos('table-of-contents'))
    setTableOfContents((prevState) => {
      return prevState.map((tableOfContent) => {
        return {
          isActive: tableOfContent.isActive,
          headingNumber: tableOfContent.headingNumber,
          headline: tableOfContent.headline,
          yPos: tableOfContent.headline ? elementYPos(tableOfContent.headline) : 0,
        }
      })
    })
  }, [])

  useEffect(() => {
    const onScroll = () => {
      let temTableOfContentsYPos = tableOfContentsYPos === 0 ? elementYPos('table-of-contents') : tableOfContentsYPos
      setIsFixed(window.scrollY > temTableOfContentsYPos - yPosOffset)

      setTableOfContents((prevState) => {
        prevState.forEach((tableOfContent) => {
          tableOfContent.yPos = tableOfContent.headline ? elementYPos(tableOfContent.headline) : 0
        })
        if (prevState[0] && prevState[0].yPos > window.scrollY) {
          prevState[0].isActive = true
          return prevState
        } else {
          let isActive = false
          return prevState.reverse().map((tableOfContent) => {
            if (isActive === false && window.scrollY >= tableOfContent.yPos - yPosOffset) {
              isActive = true
              return {
                isActive: true,
                headingNumber: tableOfContent.headingNumber,
                headline: tableOfContent.headline,
                yPos: tableOfContent.yPos,
              }
            } else {
              return {
                isActive: false,
                headingNumber: tableOfContent.headingNumber,
                headline: tableOfContent.headline,
                yPos: tableOfContent.yPos,
              }
            }
          }).reverse()
        }
      })
      setScrollTop(window.scrollY)
    };
    onScroll()
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, [scrollTop]);

  return (
    <div id="table-of-contents" className={`w-64 ${fixedClass}`}>
      <div className="font-bold mb-4">目次</div>
      <ul className="relative">
        {tableOfContents.map((tableOfContent, i) =>
          <li key={i} className={`headline${tableOfContent.headingNumber} ${tableOfContent.isActive ? 'active text-gray-800' : 'text-gray-400'} font-bold break-words text-sm relative pl-5 pb-3`}>
            <AnchorLink offset={yPosOffset} href={`#${tableOfContent.headline}`}>{tableOfContent.headline}</AnchorLink>
          </li>
        )}
      </ul>
    </div>
  )
}

export default TableOfContents