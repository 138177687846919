import Layout from "../components/layout"
import Seo from "../components/seo"
import TableOfContents from "../components/table-of-contents"
import { useLocation } from "@reach/router"
import { Link } from "gatsby"

type Props = {
  pageContext: {
    content: GatsbyTypes.ContentfulArticles
  }
}

export default function Article({ pageContext }: Props) {
  const content = pageContext.content
  const { title, createdAt, thumbnail } = content
  const description = content.description?.description
  const body = content.body?.childMarkdownRemark?.html?.replace(/<h(\d)>(.+?)<\/h\d>/g, '<h$1 id="$2">$2</h$1>');

  const thumbnailUrl = `https:${thumbnail?.file?.url}`
  const location = useLocation()

  return (
    <Layout pageType="article">
      <Seo title={title} url={location.href} image={thumbnailUrl} description={description} />
      <div className="pt-24 sm:mx-14 xl:mx-auto max-w-6xl flex flex-col lg:flex-row">
        <main className="lg:mr-10 lg:w-[calc(100%-18.5rem)]">
          <article>
            <div>
              <img src={thumbnailUrl} className="w-full" alt={title} />
            </div>
            <div className="px-4 py-8 border-b-2 space-y-4">
              <div className="font-bold text-gray-400">{createdAt}</div>
              <div className="flex space-x-1 text-gray-500 text-sm">
                {content.metadata?.tags?.map(tag =>
                  <Link key={tag?.contentful_id} to={`/tags/${tag?.contentful_id}`} className="border py-1 px-2 hover:border-gray-400 transition-all">
                    {tag?.name}
                  </Link>
                )}
              </div>
              <h1 className="font-bold text-2xl sm:text-4xl">{title}</h1>
            </div>
            <div id="article-body" dangerouslySetInnerHTML={body ? { __html: body } : { __html: '' }} className="px-4 py-8 max-w-none prose prose-sm sm:prose" />
          </article>
        </main>
        <aside className="hidden lg:block w-64">
          <TableOfContents bodyHtml={content.body?.childMarkdownRemark?.html} />
        </aside>
      </div>
    </Layout>
  )
}
